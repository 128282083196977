const userDatabase = [
  {
    username: "testing",
    password: "secret",
  },
  {
    username: "dattabot",
    password: "password",
  },
];

export { userDatabase };
